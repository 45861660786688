<template>
<div>
     <ServerError v-if="ServerError" />
  <v-layout justify-center wrap>
        <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="blue"
        spinner="mini-spinner"
      />
    <v-flex xs12 v-if="purchaseItem" pa-6 align-self-center>
      <v-card outlined elevation="2">
        <v-layout wrap>
          <v-flex xs12 sm7 md8 lg8 pt-4 px-4>
            <span class="nsbold" style="font-size:20px">
             Purchase History
            </span>
          </v-flex>
          <v-flex xs12 sm5 md4 lg4 pt-4 px-4>
            <span class="nsbold" style="font-size:20px">
             Shop name: {{$route.query.name}}
            </span>
          </v-flex>
        </v-layout>
          <!----------------------filters--------------------------------->
        <v-layout wrap>
          <v-flex xs12 lg3 px-4 px-lg-0 pl-lg-4 pt-6>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="fromDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="From"
                  outlined
                  readonly
                  dense
                  clearable
                  class="rounded-0"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                scrollable
                @change="$refs.menu.save(fromDate)"
              >
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 lg3  px-4 px-lg-0 pl-lg-4 pt-6>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="toDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="To"
                  readonly
                  outlined
                  dense
                  clearable
                  class="rounded-0"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                scrollable
                @change="$refs.menu2.save(toDate)"
              >
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
          </v-flex>
         <v-flex xs12 lg3  px-4 px-lg-0 pl-lg-4 pt-6>
           <v-text-field
              v-model="keyword"
              dense
              class="rounded-xl"
              label="Search"
              outlined
              hide-details 
              clearable
            ></v-text-field>
         </v-flex>
         <v-flex xs12 lg3  px-4 px-lg-0 pl-lg-4 pt-6>
           <v-btn 
                color="#766BC0"
                class="rounded-xl white--text"
                title="Map Student"
                 @click="$router.push({ path: '/adminFood' })"
                >Back to Food Report</v-btn
              >
         </v-flex>
         
        </v-layout>
        <!------------------------------------------------------->
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr class="nsregular">
                <th class="text-left">Bill No.</th>
                <th class="text-left">Name</th>
                <th class="text-left">Quantity</th>
                <th class="text-left">Price(Rs)</th>
                <th class="text-left">Total(Rs)</th>
                <th class="text-left">Purchase Date</th>
                <th class="text-left">Amount Paid</th>


              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, i) in purchaseItem" :key="i">
                <!-- <td>{{ i + 1 }}</td> -->
                <td>{{ value.purchaseId.billNo }}</td>
                <td>{{ value.itemName.itemName }}</td>
                <td>{{ value.quantity}}({{ value.itemName.quantityType }})</td>
                <td>{{ value.price }}</td>
                <td>{{ (value.quantity * value.price).toFixed(2) }}</td>
                <td>{{ formatDate(value.purchaseId.purchaseDate) }}</td>
                <td>{{ value.purchaseId.amountPaid }}</td>
                
                <!-- <td>{{ value.purchaseId.shopName.shopName }}</td> -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
        <v-pagination small v-model="page" :length="Pagelength"> </v-pagination>
        <v-snackbar
        :timeout="3000"
        :value="true"
        right
        color="red"
        v-model="showsnackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
    </v-flex>
  </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
        menu: false,
        menu2: false,
         showsnackbar: false,
         checkk: null,
      msg: null,
        keyword: null,
      ServerError: false,
      appLoading: false,
        page: 1,
      Pagelength: 0,
      purchaseItem: [],
    //   fromDate: "",
    //   toDate: "",
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  mounted() {
    if (this.fromDate) {
      var dt =new Date()
      
      // var day=dt.getDate()
      dt.setDate(dt.getDate() -  30+1);
      this.fromDate = dt.toISOString().substr(0, 10);
    }
    this.getData();
    
  },
   watch: {
       keyword() {
      this.getData();
    },
    page() {
      // this.getData();
      this.getData();
    },
    hostel() {
      this.getData();
    },
    fromDate() {
      // this.getData();
      this.getData();
    },
    toDate() {
      // this.getData();
      this.getData();
    },

    // from() {

    //   this.pendingData();
    // },
    // to() {

    //   this.pendingData();
    // },
   
    
    cvalue() {
      // this.getData();
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/report/generation/supplierwise",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          limit: 20,
          from: this.fromDate,
          to: this.toDate,
          shopid: this.$route.query.id,
          keyword: this.keyword,

        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.purchaseItem = response.data.data;
            // this.checkk = response.data.data.purchaseId.purchaseDate;

            // console.log(this.checkk)
            // this.shopDetails = response.data.purchaseDetails;
             this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
            this.ServerError = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year 
        // +
        // " , " +
        // hours +
        // ":" +
        // minutes +
        // " " +
        // ampm;

      return strTime;
    },
  },
};
</script>